<template>
  <div class="clientes">
    <h1 class="titulo-card fs-4">Grupos de IP Pool's / IP Pool's</h1>
    <div class="form-group card-principal">
      <div class="row justify-content-between">
        <div class="col-auto mb-3">
          <button v-on:click="handleAdicionar()" class="btn btn-success">
            <font-awesome-icon :icon="['fas', 'plus']" fixed-width class="me-1"/>Adicionar
          </button>
        </div>

        <div class="col input-group mb-3">
          <input
              v-on:keyup.enter="handlePesquisa()"
              type="text"
              class="form-control"
              placeholder="Pesquisar"
              aria-label="Pesquisar"
              aria-describedby="button-addon2">
          <button v-on:click="handlePesquisa()" class="btn btn-outline-secondary" type="button" id="button-addon2">
            <font-awesome-icon :icon="['fas', 'search']" fixed-width class="me-1"/>
          </button>
        </div>
      </div>
      <div class="d-flex">
        <table class="table table-sm-pc table-striped table-hover">
          <thead>
            <tr>
              <th>Identificação</th>
              <th>CIDR</th>
              <th>Camada IP</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="ipPool in listaItens" :key="ipPool.id" v-on:click="handleEditaIPPool(ipPool)" v-bind:class="listPoolClass(ipPool)" class="cursor-pointer">
                <td scope="row">{{ipPool.description}}</td>
                <td>{{ipPool.cidr}}</td>
                <td>{{ipPool.type}}</td>
              </tr>
            </tbody>
        </table>
      </div>
      <Paginador v-model="paginador" @change-paginacao="onChangePaginacao"/>

      <!-- modal-edita-ip-pool -->
      <!-- Button trigger modal -->
      <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-edita-ip-pool" id="btn-modal-edita-ip-pool-abre">
        Launch
      </button>

      <!-- Modal -->
      <div class="modal fade" id="modal-edita-ip-pool" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 v-if="!modal.data.id" class="modal-title">
                <font-awesome-icon :icon="['fas', 'plus']" fixed-width/>
                Cadastrar novo IP-Pool
              </h5>
              <h5 v-if="!!modal.data.id" class="modal-title">
                <font-awesome-icon :icon="['fas', 'pen']" fixed-width/>
                Editando IP-Pool
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <label class="custom-control custom-checkbox">
                    <input type="checkbox" v-model="modal.data.active" value="checkedValue" class="custom-control-input">
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description ps-1">Ativo</span>
                  </label>
                </div>
                <div class="col-12 col-sm-12 col-md-3 col-lg-5">
                  <label class="required">Identificação</label>
                  <input type="text" v-model="modal.data.description" class="form-control">
                </div>
                <div class="col-12 col-sm-4 col-md-3 col-lg-3">
                  <label class="required">Pool CIDR</label>
                  <input type="text" v-model="modal.data.cidr" class="form-control" placeholder="100.64.0.0/10">
                </div>
                <!-- <div class="col-12 col-sm-4 col-md-3 col-lg-2">
                  <label class="required">Destino</label>
                  <select v-model.number="modal.data.tipo_grupo" disabled class="form-select">
                    <option value="undefined" disabled>Selecione</option>
                    <option value="tgDistribuicao">Distribuição</option>
                    <option value="tgBloqueio">Bloqueio</option>
                    <option value="tgNaoAutenticados">Não autenticados</option>
                  </select>
                </div> -->
                <div class="col-12 col-sm-4 col-md-3 col-lg-2">
                  <label class="required">Camada IP</label>
                  <select v-model.number="modal.data.type" class="form-select">
                    <option value="undefined" disabled>Selecione</option>
                    <option value="ipv4">IPv4</option>
                    <option value="ipv6" disabled>IPv6</option>
                    <option value="tiIPv6PD" disabled>IPv6 prefix delegation</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="d-flex justify-content-between w-100">
                <button type="button" v-on:click="handleRemoveIPPool(ipPool)" class="btn btn-outline-danger float-start">
                  <font-awesome-icon :icon="['fas', 'trash']" fixed-width/>Remover
                </button>

                <div>
                  <button type="button" class="btn btn-outline-secondary me-1" data-bs-dismiss="modal" id="btn-modal-edita-ip-pool-fecha">Fechar</button>
                  <button type="button"
                      v-if="!modal.data.id"
                      v-on:click="handlemodalCadastrar()"
                      v-bind:disabled="!podeSalvarIPPool()"
                      class="btn btn-success">
                    Cadastrar
                  </button>
                  <button type="button"
                      v-if="!!modal.data.id"
                      v-on:click="handlemodalSalvar()"
                      v-bind:disabled="!podeSalvarIPPool()"
                      class="btn btn-success">
                    Salvar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- modal-edita-ip-pool -->
    </div>
  </div>
</template>

<script>
import Paginador from '@/components/Paginador.vue'
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import clone from '@/modules/clone'
import model from '@/model'

export default {
  name: 'IPPools',
  components: {
    Paginador,
  },
  data() {
    return {
      /** @type {model.paginador} */
      paginador: clone(model.paginador),
      listaItens: [],
      modal: {
        data: {
          id: undefined,
          active: true,
          description: '',
          type: '',
          cidr: '',
          // tipo_grupo: '',
        },
      }
    }
  },
  methods: {
    handlePesquisa() {
      loading(true);

      api.get(`/isp/pools?${this.paginador.requestParams}`).then(res => {
        loading(false);
        this.listaItens = res.data.items;
        this.paginador.pagina = res.data.page;
        this.paginador.totalItens = res.data.totalItems;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    onChangePaginacao() {
      this.handlePesquisa();
    },

    handleAdicionar() {
      this.resetmodal();
      this.abreModalCadastroIPPool();
    },

    async handleEditaIPPool(ipPool) {
      this.resetmodal();

      this.modal.data = clone(ipPool);

      this.abreModalCadastroIPPool();
    },

    listPoolClass(pool) {
      if (pool.active) {
        return ['text-info']
      }

      return ['text-muted'];
    },

    handleRemoveIPPool() {
      dialogo.confirmacao(`Confirma remover essa pool?`).then(() => {
        loading(true);

        api.delete(`/isp/pools/${this.modal.data.id}`).then(() => {
          loading(false);
          document.getElementById('btn-modal-edita-ip-pool-fecha').click();
          this.handlePesquisa();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    abreModalCadastroIPPool() {
      document.getElementById('btn-modal-edita-ip-pool-abre').click();
    },

    resetmodal() {
      this.modal.data.id = undefined;
      this.modal.data.active = true;
      this.modal.data.description = '';
      this.modal.data.cidr = '';
      this.modal.data.tipo_grupo = '';
      this.modal.data.type = '';
    },

    podeSalvarIPPool() {
      switch (true) {
        case (this.modal.data.description.length < 3):
        case (this.modal.data.cidr.length < 9):
        case (!this.modal.data.type):
          return false;

        default:
          return true;
      }
    },

    handlemodalCadastrar() {
      const sendData = {...this.modal.data};

      loading(true);

      api.post('/isp/pools', sendData).then(() => {
        loading(false);
        document.getElementById('btn-modal-edita-ip-pool-fecha').click();
        this.handlePesquisa();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    handlemodalSalvar() {
      const sendData = {...this.modal.data};

      loading(true);
      api.put(`/isp/pools`, sendData).then(() => {
        loading(false);
        document.getElementById('btn-modal-edita-ip-pool-fecha').click();
        this.handlePesquisa();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },
  },
  created() {
    this.handlePesquisa();

    // document.getElementById('btn-modal-edita-ip-pool-fecha').click();
    /*
    setTimeout(() => {
      this.handleAdicionar();

      this.modal.data.description = 'BNG Teste Front';
      this.modal.data.senha_radius = '123465';
      this.modal.data.pool_ipv4_acesso_id = 0;
      this.modal.data.pool_ipv4_bloqueio_id = 0;
    }, 500);
    /* */
  }

}


</script>
